@import "system/variables";

.footer {
    background: url(/images/bg2.jpg) center center no-repeat;
    ;
    background-size: cover;
    position: relative;
    color: #fff;
    padding-bottom: 20px;

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
    }

    &__logo {
        margin-bottom: 20px;
    }

    &__inner {
        @include respond-to(920) {
            flex-wrap: wrap;
        }
    }

    .footer__col {
        @include respond-to(920) {
            flex-basis: 50%;
            margin-bottom: 20px;
        }
    }

    &__title {
        font-family: $ff-sec;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 20px;
        letter-spacing: 1px;
    }

    &__info {
        font-size: 14px
    }

    &__schedule {
        margin-bottom: 20px;

        p {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 7px;
            font-size: 14px;

            span {
                &:first-child {
                    padding-right: 5px;
                }
            }

            a {
                &:hover {
                    text-decoration: underline;
                    color: #fff;
                }
            }
        }
    }
    &__mail { 
        color: #fff;
        padding: 5px 10px; 
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        border-radius: 0 0 4px 4px;

        margin-top: 40px; 
    }

    &__mail-icon {
        color: #fff;
        border-radius: 50%;
        display: flex;
        width: 27px;
        height: 27px;
        border: 1px solid #fff;
        justify-content: center;
        align-items: center;
        margin-right: 8px; 
    }

    &-mail-text {
        color: #fff;
        letter-spacing: 1px;
        font-size: 15px;
        font-family: $ff;
    }

    &__bottom {
        text-align: center;
        font-size: 14px;
        padding: 20px 0;
    }
}