@import "system/variables";
.color-brand{
    color: $brand;
}
.color-white{
    color: #fff;
}

.bg-grey{
    background: $bg;
}

.text-center{
    text-align: center;
}

.w100{
    width: 100%;
}
/****/
.padding{
    padding: 70px 0;
    &--top{
        padding: 0;
        padding-top: 70px;
    }
    &--bottom{
        padding: 0;
        padding-bottom: 70px;
    }
}

.mt-40{
    margin-top: 40px;
}