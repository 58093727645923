@import "system/variables";

@keyframes rotate {

    0%,
    100%,
    50% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(10deg);
    }

    75% {
        transform: rotate(-10deg);
    }
}

.header {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 0.4s ease;
    transform: translateY(0);
    border-top: 5px solid #018763;
    z-index: 999;

    &.is-small {
        transform: translateY(-100%);

        .header__top {
            display: none;
        }

        .header__left {
            flex-basis: 110px;
        }

        .header__right {
            align-self: center;
        }

        .header__logo-text {
            display: block;
            color: #eee;
            font-size: 11px;
        }
    }

    &.is-fixed {
        position: fixed;

        transform: translateY(0%);
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        // align-items: center;
    }

    &__left {
        flex-basis: 180px;
        flex-shrink: 0;
        flex-grow: 0;
        height: 100%;
        margin-right: 30px;
        padding: 8px 0 5px;

        @include respond-to(xs) {
            flex-basis: 100px;
        }
    }

    &__logo {
        display: block;
        height: 100%;
        width: 100%;

        &-img {
            width: 100%;
        }

        &-text {
            display: block;
            color: #eee;
            font-family: $ff-sec;
            font-size: 16px;
            font-weight: 500;
            text-align: center;

            @include respond-to(xs) {
                font-size: 14px;
            }
        }
    }

    &__right {
        flex-grow: 1;
        height: 100%;

        @include respond-to(xs) {
            align-self: center;
        }
    }

    &__top {
        border-bottom: 1px solid #eee;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 3px;

        @include respond-to(xs) {
            border: none;
            height: 100%;
        }

        &-col {
            flex-basis: 33%;

            @include respond-to(small) {
                flex-basis: 66%;
                text-align: center;
            }

            &:first-child {
                @include respond-to(xs) {
                    flex-basis: 100%;
                    padding-right: 80px;
                }
            }

            &:last-child {
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @include respond-to(small) {
                    flex-basis: 33%;
                }

                @include respond-to(xs) {
                    display: none;
                }
            }

        }

        &-btn {
            background: $brand;
            color: #fff;
            padding: 5px 10px;
            border: 1px solid $brand;
            font-size: 14px;
            display: inline-flex;
            align-items: center;
            flex-grow: 0;
            flex-shrink: 0;
            text-align: center;
            border-radius: 0 0 4px 4px;

            @include respond-to(410) {
                display: none;
            }
        }

        &-btn-border {
            border: 1px solid #fff;
            border-radius: 50%;
            display: flex;
            width: 27px;
            height: 27px;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
        }

        &-btn-icon {
            color: #fff;
            animation: rotate 1.7s linear infinite;
        }

        &-btn-text {
            color: #fff;
            letter-spacing: 1px;
            font-size: 15px;
            font-family: $ff;
        }

        &-link {
            color: $orange;
            font-size: 16px;
            font-family: $ff-sec;
            transition: all 0.6s ease;
            padding-right: 15px;

            &:hover {
                color: $orange;
                opacity: 0.8;
            }
        }
    }

    &__menu {
        padding: 5px 10px;

        @include respond-to(xs) {
            height: 0;
            padding: 0;
        }
    }

}

.menu {
    height: 100%;
    transform: translateX(0);

    @include respond-to(small) {
        // display: none;
        display: block;
        position: fixed;
        height: 100vh;
        overflow: auto;
        background: #283646;
        left: 0px;
        top: 0;
        opacity: 1;
        z-index: 99;
        width: 500px;
        width: 100vw;
        max-width: 100%;
        transition: all .6s cubic-bezier(.23, 1, .32, 1);
        transform: translateX(-100%);
    }

    &__close {
        display: none;
        font-size: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #eee;
        padding: 20px 15px;

        @include respond-to(small) {
            display: block;
            color: #fff;
            padding: 28px 15px;
        }

        &-text {
            font-family: $ff;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            position: relative;
            line-height: 1;
            transition: transform .5s ease;
            overflow: hidden;
        }

        &-icon {
            @include respond-to(small) {
                display: flex;
                align-items: center;
            }

            svg {
                @include respond-to(small) {
                    width: 20px;
                    fill: #fff;
                    margin-right: 16px;
                    height: 15px;
                }

                path {
                    @include respond-to(small) {
                        fill: #fff;
                    }
                }
            }
        }
    }

    &__open {
        display: none;
        width: 60px;
        bottom: 0;
        right: 25px;
        padding: 5px;
        transition: top .6s cubic-bezier(.23, 1, .32, 1);
        white-space: nowrap;
        position: absolute;
        cursor: pointer;

        @include respond-to(small) {
            display: block;
        }

        @include respond-to(xs) {
            bottom: auto;
            top: 15px;
            right: 15px;
        }

        span {
            display: block;
            background: #fff;
            transition: all .6s cubic-bezier(.23, 1, .32, 1);
            width: 100%;
            height: 3px;
            margin-bottom: 5px;
            background: $orange;

            &:last-child {
                width: 50%;
            }
        }

        &:hover {
            span {
                &:last-child {
                    width: 100%;
                }

            }
        }
    }

    &__items {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include respond-to(small) {
            display: block;
            height: auto;
        }
    }

    &__link {
        font-family: $ff;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        padding: 13px 10px 11px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        position: relative;
        line-height: 1;
        overflow: hidden;

        @include respond-to(small) {
            display: block;
            padding: 25px 20px;
            font-size: 16px;
            transform: translateY(0px);
            opacity: 1;
            text-align: center;
        }

        &:before {
            content: '';
            height: 2px;
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0%;
            transition: transform .5s ease;
            background: $orange;
            transform: translateX(-100%);

            @include respond-to(small) {
                bottom: 0%;
                transform: translateX(-50%);
                width: 40px;
                left: 50%;
            }

            @include respond-to(500) {
                width: 96%;
            }


        }


        &:after {
            display: none;
            content: '';
            height: 2px;
            position: absolute;
            left: 0;
            width: 100%;
            top: 0%;
            transition: transform .5s ease;
            transform: translateX(100%);
            background: $brand;
        }

        &:active,
        &:focus {
            color: #fff;
        }

        &:hover {
            color: $orange;

            &:before {
                transform: translateX(0%);
            }

        }
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 20px;

        @include respond-to(small) {
            margin: 0;
            display: block;
            // display: inline-block;
            text-align: center;
            display: flex;
            flex-direction: column;
        }
    }

    &__btn {
        margin-right: 10px;
        border: 1px solid #fff;
        border-radius: 4px;
        font-family: $ff;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        padding: 6px 14px 4px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        transition: background .5s ease, color .5s ease;

        @include respond-to(small) {
            transform: translateY(0px);
            opacity: 1;
            min-width: 200px;
            display: inline-block;
            max-width: 90%;
            border: 0;
            font-size: 16px;
            background: $brand;
            margin: 15px 15px 10px;
            text-align: center;
            color: #fff;
            background: $brand;
            border-color: $brand;
            padding: 10px 20px;
        }

        @include respond-to(500) {
            width: 100%;
        }

        &:last-child {
            margin-right: 0;

            @include respond-to(small) {
                margin: 10px auto;
            }
        }

        &--brand {
            border-color: $brand;
            background: $brand;
            color: #fff;

            &:hover {
                background: #000;
            }

            @include respond-to(small) {
                color: #fff;
                background: $brand;
                border-color: $brand;
            }
        }

        &:hover {
            text-decoration: none;
            color: #fff;
            background: #000;
            border-color: #000;

            @include respond-to(small) {
                text-decoration: none;
                color: #fff;
                background: $brand;
                border-color: $brand;
            }
        }
    }
}