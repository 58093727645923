@import "system/variables";

/***  Preloader ***/
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.preload {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
z-index: 9999;
    &__loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 100px;
        height: 100px;
        margin: -50px 0 0 -50px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: $brand;
        animation: spin 2s linear infinite;

        &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: $orange;
            animation: spin 3s linear infinite;
        }

        &::after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: $bright;
            animation: spin 1.5s linear infinite;
        }
    }
}

/*** End Preloader ***/
.page-padding{
    padding-top: 95px;
   @include respond-to(470){
    padding-top: 75px;
   }
}
/*** Parallax ***/
.parallax {
    // height: 400px;
    background-color: #000;
    position: relative;
    overflow: hidden;
 


    &__bg {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 150%;
        top: 0%;
        z-index: 1;
        // opacity: .7;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }

    &__content {

        position: relative;
        z-index: 9;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        &-wrap {
            width: 100%;
            height: 100%;

            .site-width {
                height: 100%;

            }
        }
    }


}

/*** End Parallax ***/

/***  Button  ***/
.btn { 
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    transition: all .4s;
    display: inline-block;
    position: relative; 
    background: $brand;
    border: 1px solid $brand;
    color: #fff;
    overflow: hidden;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 8px 22px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
    max-width: 100%;
    min-width: 150px;
    border-radius: 4px;

    &--orange {
        background: $orange;
        border-color: $orange;
    }

    &--grey {
        background: #999;
        border-color: #999;
    }
    &--green {
        background: rgb(194, 186, 45);
        border-color: rgb(194, 186, 45);
    }
    // &--white {
    //     background:rgba(255,255,255,0.8);
    //     border-color: $brand;
    //     color: $brand;
    // }

    &:hover,
    &:active {
        color: #fff;
        background: #000;
        border-color: #fff;
        ;
    }
    &.btn-yellow{
        background: yellow;;
        border: 1px solid yellow;;
        color: #000;
        &:hover{
            background: yellow;;
            border: 1px solid yellow;;
        color: #000;
        opacity: 0.8;
        }
    }
}
/***  End Button  ***/

/***  Content  ***/
.block {
    width: 100%;
    &--grey {
        background: $bg;
    }

    &__content {
        max-width: 100%;
        width: 900px;
        margin: 0% auto;
    }

}

.title, .page_title {
    font-family: $ff-sec;
    font-size: 34px;
    font-weight: 700;
    color: #333;
    text-align: center;
    position: relative;
    opacity: 1;
    letter-spacing: 1px;
    margin-bottom: 30px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        height: 5px;
        width: 160px;
        background: $brand;

    }
    &--blue{
        &:after { 
            background: #5780c6;
    
        }
    }
}
.subtitle{
    text-align: center;
    font-size: 20px;
}
/***  End Content  ***/

/***  Flex Grid ***/
.flex{
    display: flex; 
    justify-content: flex-start;
    &--center{
        display: flex; 
        align-items: center;
        justify-content: center; 
    }
    &--between{
        justify-content: space-between;
    }
    &--col3{
        justify-content: space-between;
        @include respond-to(small){
            flex-wrap: wrap;
        }
        .col{
            flex-basis: 33.33%;
            padding: 15px; 
            flex-grow: 1;
            @include respond-to(xs){
                flex-basis: 100%;
                margin-bottom: 15px;
            }
            &__inner{
                height: 100%;
            }
        }
    } 
    &--col4{
        justify-content: space-between;
        @include respond-to(small){
            flex-wrap: wrap;
        }
        .col{
            flex-basis:25%;
            padding: 15px; 
            flex-grow: 1;
            @include respond-to(small){
                flex-basis: 50%;
                margin-bottom: 15px;
            }
            @include respond-to(xs){
                flex-basis: 100%; 
            }
            &__inner{
                height: 100%;
            }
        }
    } 
}
/***  End Flex Grid  ***/

/***  Form  ***/
.form{
    .form-group {
        margin-bottom: 10px;
        label {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 700;
        }
        &>div{
            height: 100%;
        }
    }
    .form-control {
        display: block;
        width: 100%;
        line-height: 1.4;
        color: #333;
        background-color: #fff;  
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075); 
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; 
        height: 40px;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .help-block{
        color: rgb(170, 0, 0);
    } 
}


.form{
    &__wrap{

    display: flex;
    flex-wrap: wrap;
    
    }
    &__left{
        flex-basis: 66%;
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include respond-to(small){
        flex-basis: 100%;
        }
        .form-group{
            flex-grow: 0;
            flex-shrink: 0;
            &:first-child{
                flex-basis: 48%;
                @include respond-to(small){
                    flex-basis: 100%;
                }
            }
            &:nth-child(2){
                flex-basis: 48%;
                @include respond-to(small){
                    flex-basis: 100%;
                }
            }
            &:nth-child(3), &:nth-child(4), &:nth-child(5){
                flex-basis: 31%;
                @include respond-to(small){
                    flex-basis: 100%;
                }
            }
        }
    }
    &__right{
        flex-basis: 33%;
        padding: 0 15px;
        // height: 100%;
        @include respond-to(small){
            flex-basis: 100%;
            }
        .form-group{
            height: 100%;
        }
        textarea.form-control{
            height: calc(100% - 42px);
            max-height: none;
            @include respond-to(small){
                min-height: 150px;
                }
        }
    }
    &__btn{
        flex-basis: 100%;
    }
}
/***  End Form ***/