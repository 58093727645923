.pricing-more{

    .title{
        margin-bottom: 10px;
        text-align: left;
        display: inline-block;
        &:after{
            left: 0;
            transform: translateX(0);
            width: 100%;
        }
    }
    .subtitle{
        margin-bottom: 20px;
    }
    &__section{
        margin-bottom: 30px;
    }
    &__content{
        width: 100%;
        // max-width: 700px;
        max-width: 870px;
        margin: auto;
        // text-align: center;
// h3{
//     font-size: 20px;
//     font-weight: bold;
//     font-family: "Raleway",sans-serif; 
//     color: #333; 
//     position: relative;
//     opacity: 1;
//     letter-spacing: 0.5px;
//     margin-bottom: 20px;
//     // text-align: center;
//     &:after {
//         content: '';
//         position: absolute;
//         bottom: 0;
//         left: 0%; 
//         transform: translate(0%,100%);
//         height: 5px;
//         width: 180px;
//         background: rgb(87,128,198);
//     }
// }
    }
    &__row{
        margin-bottom: 10px; 
        color: rgb(55, 188,  239);
    }
    &__title{
        font-weight: bold;
        font-size: 24px;
            small{
                font-weight: inherit;
            }

    }
        &__price{
            font-weight: inherit;
        }
    &__descr{
        font-size: 14px;
        font-weight: bold;
        font-style: italic;
    }
}