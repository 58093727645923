@import "system/variables";

.hero {
    height: 100vh;
    min-height: 670px;

    .parallax {
        &__bg {
            background: url('/images/hero.jpg');
        }
    }

    &__content {
        padding: 30px;
        // border: 6px solid $brand;
        background: rgba(0, 0, 0, 0.6);
        width: 790px;
        max-width: 100%;
        color: #fff;
        text-align: center;
    }

    &__title {
        font-weight: 700;
        margin: 0;
        position: relative;
        color: #fff;
        font-size: 30px;
        font-family: $ff-sec;
        text-align: center;
        opacity: 1;
        letter-spacing: 1px;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        margin-bottom: 30px;
text-transform: uppercase;
        @include respond-to(670) {
            font-size: 22px;
        }

        span {
            font-weight: bold;
        }
    }

    &__btns {
        display: flex;
        justify-content: space-around;

        @include respond-to(670) {
            flex-wrap: wrap;

        }

        .btn {
            margin: 0 10px;
            flex-grow: 1;

            @include respond-to(670) {
                margin-bottom: 15px;

            }
        }
    }

}

.service {
    &__item {
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    &__img-link {
        display: block;
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;

        img {
            width: 100%;
            display: block;
            transition: all 0.8s ease;
        }

        &:hover {

            img {
                transform: scale(1.1);
            }

            .service__title {

                span {
                    color: #fff;
                }

                &:after {
                    width: 100%;
                }
            }
        }
    }

    &__title {
        font-family: $ff-sec;
        position: absolute;
        bottom: 0;
        opacity: 1;
        background: transparent;
        color: $brand;
        padding: 8px 10px;
        text-align: center;
        width: 100%;
        background: rgba(255, 255, 255, 0.6);


        span {
            z-index: 1;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 1px;
            position: relative;
            transition: ease all .55s;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background: $brand;
            transition: ease all .5s;
            width: 0px;
            z-index: 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__text {
        font-size: 15px;
        line-height: 1.6;
        text-align: center;
        margin-bottom: 20px;
    }

    &__btns {
        margin-top: auto;
    }

    .col {
        @include respond-to(690) {
            flex-basis: 100%;
            margin-bottom: 15px;
        }
    }
}

.car {
    overflow: hidden;
    position: relative;

    &.in-view {
        .car__left {
            transform: translate(0%, 0px);

            .car__wheel {
                transform: rotate(600deg);
            }
        }

        .car__right {
            transform: translate(0%, 0px);

            .car__wheel {
                transform: rotate(-600deg);
            }
        }

    }

    &__left {
        position: absolute;
        transition: all 1s ease-out;
        width: 45%;
        transform: translate(-100%, 0px);
        left: 0;

        @include respond-to(small) {
            width: 100%;
        }
    }

    &__right {
        position: absolute;
        transition: all 1s ease-out;
        width: 45%;
        transform: translate(100%, 0px);
        right: 0;

        @include respond-to(small) {
            width: 100%;
        }

        .car__wheel {
            position: absolute;
            right: 60.9%;
            bottom: 10.7%;
            width: 20.8%;
        }
    }

    &__inner {
        position: relative;
    }

    &__img {
        width: 100%;
    }

    &__wheel {
        position: absolute;
        right: 18.5%;
        bottom: 10.7%;
        width: 20.8%;
        transition: all 1s ease-out;
        // .car__right{

        //         position: absolute;
        //         right: 60.9%;
        //         bottom: 10.7%;
        //         width: 20.8%;
        //         @include respond-to(small){
        //             width: 100%;
        //         }

        // }
    }

    &__content {
        padding-bottom: 23%;
        position: relative;

        @include respond-to(992) {
            padding: 0;
        }

        @include respond-to(small) {

            padding: 0;
            margin-top: 54%;
        }

        .site-width {
            display: flex;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            align-items: center;

            @include respond-to(992) {
                position: static;
                transform: translateX(0%);
            }
        }

        &-right {
            justify-content: flex-end;
        }

        &-left {
            justify-content: flex-start;
        }
    }

    &__text {
        flex-basis: 50%;

        @include respond-to(small) {
            flex-basis: 100%;
        }
    }
}

.pricing {
    &__item {
        border: 1px solid #fff;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        background-color: #fff;
        padding: 30px 20px;
        border-radius: 6px;
        -webkit-box-shadow: 0 10px 30px 0 rgba(5, 16, 44, .15);
        box-shadow: 0 10px 30px 0 rgba(5, 16, 44, .15);
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-family: $ff-sec;
        color: $brand;
        padding: 8px 10px;
        text-align: center;
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1px;
        position: relative;
        transition: ease all .55s;
        margin-bottom: 20px;
        line-height: 1.4;
flex-basis: 100%;
    }

    &__price {
        font-family: $ff;
        padding: 15px 10px;
        border-top: 1px solid $brand;
        border-bottom: 1px solid $brand;
        text-align: center;
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        position: relative;
        transition: ease all .55s;
        margin-bottom: 20px;
        flex-basis: 100%;
        &--blue{
            // font-family: $ff-sec;
            color: #37bcef;
            font-weight: 700;
            font-size: 24px;
        }
    }

    &__descr {
        font-family: $ff;
        padding: 15px 10px;
        text-align: center;
        width: 100%;
        letter-spacing: 1px;
        position: relative;
        transition: ease all .55s;
        margin-bottom: 10px;
        // min-height: 170px;
        flex-basis: 100%;
    }

    &__list{
        ul{
            padding: 0;
            min-height: 150px;
            li{
                // text-align: left;
                font-weight: bold;
                font-size: 14px;

            }
        }
    }
    &__btn{
        flex-basis: 100%;
    }
    .flex {
        @include respond-to(768) {
            flex-wrap: wrap;
            justify-content: center;
        }

        .col {
            flex-basis: 100%;
            max-width: 370px;
            padding: 15px;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 0;
        }
    }
}

.step {
    .parallax__bg {
        height: 280%;
        top: 70%;
    }

    .parallax__overlay {
        background: rgba(0, 0, 0, 0.75);
    }

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        @include respond-to(670) {
            flex-wrap: wrap;
        }

    }

    &__left {
        // flex-basis: 80%;
        flex-grow: 1;

        @include respond-to(670) {
            flex-basis: 100%;
            margin-bottom: 20px;
        }

        li {
            list-style: disc;
            font-size: 18px;
        }
    }

    &__right {
        flex-basis: 280px;
        text-align: center;
        display: flex;
        align-items: center;

        @include respond-to(670) {
            flex-basis: 100%;
        }

        .btn {
            min-width: 200px;
        }
    }
}

.how {
    &__content {
        padding-bottom: 28%;

        li {
            list-style-type: disc;

        }
    }
}