@import "system/variables";
/* Global */
html{
    position: relative;
}
body {
    font-family: $ff,sans-serif;
    font-size: 16px;
    line-height: 1.8;
    color: $text;
    background-color: #fff;
    font-weight: 400;
}
p {
    font-family: $ff,sans-serif;
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 15px;
}

a {
    font-size: inherit;
    font-weight: inherit;
    &:hover{
        color: $brand;
    }
}
ul{
    padding-left: 30px;
}
li{
    margin-bottom: 7px;
    line-height: 1.4;
}
img {
    max-width: 100%;
}
sup{
    font-size: 60%;
}
.site-width{ 
    position: relative; 
    max-width: 100%;
    width: 1100px;
    padding: 0 15px;
    margin: 0 auto;
}